import React, { useState, useEffect } from 'react'
import AutoComplete from 'react-google-autocomplete'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox } from 'semantic-ui-react'
// Components
import { Seo, Primary as SaveButton, Loading, PageHeader } from '../components'
import { useStore } from '../context/StoreContext'
// Styles
import {
  Container,
  StyledForm,
  StyledInput,
  StyledSelect,
  ErrorMsg,
} from '../styles/LoginPage.styles'
// Hooks
import useShowError from '../hooks/useShowError'
// Services & Utils
import { Magento } from '../services/magento'
import { shippingAddressSchema } from '../utils/validations'
import { createUserAddress, updateUserAddress } from '../utils/cartHelpers'

import { usePrismic } from '../context/PrismicContext'
import { navigate } from 'gatsby'
import environment from '../utils/environment'
import {
  getFormattedDataFromPlaces,
  getMagentoRegionFromGooglePlaceRegion,
} from '../utils/googleHelpers'

const ShippingAddress = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [defaultShipping, setDefaultShipping] = useState(false)
  const [countryData, setCountryData] = useState(null)

  // state passed from Link in `shipping-info` page {type: "add | update"}
  const { state: shippingInfoState } = location

  const { handleUpdateUserData } = useStore()
  const [error, showError] = useShowError()

  const {
    prismicData: { prismicAddUpdateShippingPage },
  } = usePrismic()

  const {
    update_shipping_address,
    update_address,
    add_address,
    loading_country_data,
    first_name,
    last_name,
    address_1,
    address_2,
    city,
    zip_code,
    united_states,
    phone_number,
    set_as_default_shipping_address,
    save_changes,
  } = prismicAddUpdateShippingPage

  useEffect(() => {
    // if no shipping state passed to page (ie. user typed in URL), then navigate out
    if (!shippingInfoState) {
      navigate('/cart')
      return
    }

    ;(() =>
      Magento.Store.getCountryData({ id: 'US' }).then(({ country }) => {
        setCountryData(country)
        setLoading(false)
      }))()

    return null
  }, [])

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(shippingAddressSchema),
    defaultValues:
      shippingInfoState?.type === 'update'
        ? {
            firstname: shippingInfoState.address.firstname,
            lastname: shippingInfoState.address.lastname,
            address1: shippingInfoState.address.street[0],
            address2: shippingInfoState.address?.street[1] || '',
            city: shippingInfoState.address.city,
            state: `${shippingInfoState.address.region.region_id},${shippingInfoState.address.region.region_code},${shippingInfoState.address.region.region}`,
            postcode: shippingInfoState.address.postcode,
            telephone: shippingInfoState.address.telephone,
          }
        : null,
  })

  const onSubmit = data => {
    if (shippingInfoState.type === 'add') {
      createUserAddress(data, defaultShipping, handleUpdateUserData, navigate)
    } else {
      const {
        address: { id },
      } = shippingInfoState
      updateUserAddress(
        id,
        data,
        defaultShipping,
        handleUpdateUserData,
        navigate
      )
    }
  }

  if (loading)
    return <Loading loading={loading} message={loading_country_data[0].text} />

  return (
    <>
      <Seo title={update_shipping_address[0].text} />
      {shippingInfoState?.type === 'update' ? (
        <PageHeader url="/shipping-info">{update_address[0].text}</PageHeader>
      ) : (
        <PageHeader url="/shipping-info">{add_address[0].text}</PageHeader>
      )}
      <Container padding="1em">
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Container
            style={{ maxWidth: '500px' }}
            justify="space-between"
            padding="0"
          >
            <AutoComplete
              apiKey={environment.GOOGLE_MAP_API_KEY}
              options={{
                componentRestrictions: { country: ['us'] },
                fields: ['address_components'],
                types: ['address'],
              }}
              style={{
                padding: '10px',
                width: '100%',
                maxWidth: '500px',
                border: '1px solid #222',
                borderRadius: '10px',
                marginBottom: '1rem',
                fontSize: '16px',
              }}
              onPlaceSelected={place => {
                const formattedPlace = getFormattedDataFromPlaces(place)
                setValue('address1', formattedPlace.address1)
                setValue('city', formattedPlace.locality)
                setValue(
                  'state',
                  getMagentoRegionFromGooglePlaceRegion(
                    countryData.available_regions,
                    formattedPlace.state
                  )
                )
                setValue('postcode', formattedPlace.postcode)
                clearErrors()
              }}
            />
          </Container>
          <Container
            style={{ maxWidth: '500px' }}
            justify="space-between"
            padding="0"
          >
            <ErrorMsg>{errors.firstname?.message}</ErrorMsg>
            <ErrorMsg>{errors.lastname?.message}</ErrorMsg>
          </Container>
          {error && showError()}
          <Container
            style={{ maxWidth: '500px' }}
            justify="space-between"
            padding="0"
          >
            <StyledInput
              {...register('firstname')}
              placeholder={first_name[0].text}
              width="49%"
            />
            <StyledInput
              {...register('lastname')}
              placeholder={last_name[0].text}
              width="49%"
            />
          </Container>
          <ErrorMsg>{errors.address1?.message}</ErrorMsg>
          <StyledInput
            {...register('address1')}
            placeholder={address_1[0].text}
          />
          <ErrorMsg>{errors.address2?.message}</ErrorMsg>
          <StyledInput
            {...register('address2')}
            placeholder={address_2[0].text}
          />
          <ErrorMsg>{errors.city?.message}</ErrorMsg>
          <StyledInput {...register('city')} placeholder={city[0].text} />
          <Container
            style={{ maxWidth: '500px' }}
            justify="space-between"
            padding="0"
          >
            <ErrorMsg>{errors.state?.message}</ErrorMsg>
            <ErrorMsg>{errors.postcode?.message}</ErrorMsg>
          </Container>
          <Container
            style={{ maxWidth: '500px' }}
            justify="space-between"
            padding="0"
          >
            <StyledSelect {...register('state')} width="49%">
              {countryData &&
                countryData.available_regions.map(({ id, code, name }) => (
                  <option key={id} value={`${id},${code},${name}`}>
                    {name}
                  </option>
                ))}
            </StyledSelect>
            <StyledInput
              {...register('postcode')}
              placeholder={zip_code[0].text}
              width="49%"
            />
          </Container>
          <ErrorMsg>{errors.country_code?.message}</ErrorMsg>
          <StyledSelect {...register('country_code')}>
            <option value="US">{united_states[0].text}</option>
            {/* <option value="AU">Australia</option>
            <option value="CA">Canada</option>
            <option value="DE">Germany</option>
            <option value="FR">France</option>
            <option value="GB">United Kingdom</option>
            <option value="NL">Netherlands</option> */}
          </StyledSelect>
          <ErrorMsg>{errors.telephone?.message}</ErrorMsg>
          <StyledInput
            {...register('telephone')}
            placeholder={phone_number[0].text}
            type="telephone"
          />
          <Checkbox
            radio
            name="default_shipping"
            value="default_shipping"
            label={{
              children: (
                <Container padding="0">
                  <span>{set_as_default_shipping_address[0].text}</span>
                </Container>
              ),
            }}
            checked={defaultShipping}
            onChange={() => setDefaultShipping(!defaultShipping)}
          />

          <SaveButton
            type="submit"
            disabled={Object.entries(errors).length !== 0}
          >
            {save_changes[0].text}
          </SaveButton>
        </StyledForm>
      </Container>
    </>
  )
}

export default ShippingAddress
