import {
  AddressFormInputs,
  GooglePlacesResponse,
  MagentoRegion,
} from '../types/CartTypes'

export const getMagentoRegionFromGooglePlaceRegion = (
  regions: MagentoRegion[],
  statecode: string
) => {
  const { id, code, name } = regions.find(({ code }) => code === statecode)
  return `${id},${code},${name}`
}
export const getFormattedDataFromPlaces = (
  response: GooglePlacesResponse
): AddressFormInputs => {
  let address1, postcode, locality, state, country
  for (const component of response.address_components) {
    const componentType = component.types[0]
    switch (componentType) {
      case 'street_number': {
        address1 = `${component.long_name} `
        break
      }
      case 'route': {
        address1 += component.short_name
        break
      }
      case 'postal_code': {
        postcode = `${component.long_name}`
        break
      }
      case 'locality':
        locality = component.long_name
        break
      case 'administrative_area_level_1': {
        state = component.short_name
        break
      }
      case 'country':
        country = component.long_name
        break
    }
  }
  return {
    address1,
    state,
    country,
    locality,
    postcode,
  }
}
